import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FollowButton } from "../components/FollowButton";
import { RecipeList } from "../components/RecipeList";
import { useFollowerContext } from "../context/FollowersContext";
import { useCurrentUser } from "../context/SessionContext";
import { useRecipeList } from "../hooks/useRecipeList";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { useTitle } from "../hooks/useTitle";

export const AuthorRecipePage = () => {
  const user = useCurrentUser();
  const { following, followers } = useFollowerContext();
  const { recipes, onList } = useRecipeList();
  const { authorId } = useParams<{ authorId?: string }>();
  const { state } = useLocation();
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);

  useEffect(() => {
    onList({ authorId });
  }, [authorId]);

  const handle =
    state?.handle ||
    params.get("handle") ||
    following.find((f) => f.id === authorId)?.handle ||
    followers.find((f) => f.id === authorId)?.handle;

  useTitle(handle ? `${handle} Recipes` : undefined);

  return (
    <DefaultLayout>
      <div className="pb-3">
        <FollowButton authorId={authorId ?? ""} />

        <h1 className="text-center text-3xl py-3">
          {handle && <>{handle}'s</>}
          {(!authorId || (!handle && user?.id === authorId)) && <>My</>}
          &nbsp;Recipes
        </h1>
        <RecipeList recipes={recipes} />
      </div>
    </DefaultLayout>
  );
};
